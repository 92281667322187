export class PetRequest {
  pet_name: string;
  species: string;
  breed: string;
  dob: string;
  sex: string;
  microchip_no?: string;
  blood_group?: string;
  registering_clinic?: string;
}
