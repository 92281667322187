export class Clinic {
  _id: string;
  name: string;
  status: string;
  address: string;
  phone_no: string;
  clinic_gst: string;
  store_gst: string;
  logo_url?: any;
  email_id: string;
  license_number?: string;
  branch_name: any;
  phone_no2: any;
  store_phone_no: any;
  website: any;
}
