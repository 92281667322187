import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
// import jspdf from 'jspdf';
// import autoTable from 'jspdf-autotable';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/shared-module/constants';
import { GlobalResponse } from 'src/app/shared-module/models';
import { NonSecureService } from '../non-secure.service';

@Component({
  selector: 'app-small-store-invoice',
  templateUrl: './small-store-invoice.component.html',
  styleUrls: ['./small-store-invoice.component.scss'],
  providers: [DatePipe]
})
export class SmallStoreInvoiceComponent implements OnInit {
  mainData: any = {};
  invoice_id: string;
  base64ImageString: any;
  rowData: any[] = [];
  taxData: any[] = [];
  @BlockUI() blockUI: NgBlockUI;
  subTotal = 0;
  tax_amount = 0;
  line_discount_amount = 0;

  constructor(
    private titleService: Title,
    private toastr: ToastrService,
    private service: NonSecureService,
    private datepipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.invoice_id = this.route.snapshot.paramMap.get('invoice_id');
    if (this.invoice_id.length > 10) {
      this.fetchInvoiceDetails();
    } else {
      this.router.navigate([Constants.PET_DASHBOARD_URL]);
    }
  }
  print(): void {
    window.print();
  }
  fetchInvoiceDetails() {
    this.blockUI.start();
    this.service.fetchStoreInvoiceDetails(this.invoice_id).subscribe(
      (res: GlobalResponse) => {
        this.blockUI.stop();
        if (res && res.message && res.message === Constants.STATUS_SUCCESS && res.result) {
          this.mainData = res.result;
          this.subTotal = Math.floor(this.mainData.discount + this.mainData.amount);
          this.titleService.setTitle('Invoice ' + this.mainData.invoice_no);
          this.createMainData(this.mainData.medicines);
          if (this.mainData.tax) {
            this.taxData = this.mainData.tax;
            this.tax_amount = this.sumArrayValue(this.mainData.tax);
          }
          if (this.mainData.clinic.logo_url && this.mainData.clinic.logo_url.length > 10) {
            this.getBase64ImageFromUrl(this.mainData.clinic.logo_url).then(base64 => {
              this.base64ImageString = base64;
            });
          }
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.toastr.error(error);
      }
    );
  }

  createMainData(listData: any) {
    for (const item of listData) {
      const tempItem = {} as any;
      if (item) {
        tempItem.name = item.name;
        tempItem.category = item.category;

        tempItem.qty = item.qty;
        tempItem.price = item.unitPrice;
        tempItem.total_price = item.totalPrice;
        tempItem.original_total_price = item.original_total_price;
        tempItem.original_unit_price = item.original_unit_price;
        tempItem._id = item._id;
        tempItem.drug = item.drug;
        tempItem.discount = item.discount;
        this.line_discount_amount += item.discount;
        tempItem.tax_slab = item.tax_slab;
        tempItem.hsn = item.hsn;
        tempItem.batch = item.batch ? item.batch : null;
        // if (tempItem.price !== 0) {
        this.rowData.push(tempItem);
        // }
      }
    }
  }

  sumArrayValue(array) {
    if (array && array.length > 0) {
      return array.map(obj => obj.amount).reduce((accumulator, current) => accumulator + current, 0);
    } else {
      return 0;
    }
  }

  async getBase64ImageFromUrl(imageUrl) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result);
        },
        false
      );
      reader.onerror = () => reject(this);

      reader.readAsDataURL(blob);
    });
  }
}
