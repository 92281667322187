<div class="not-found-container">
  <div class="content">
    <img src="assets/images/Logo-03.png" alt="PawsNme Logo" class="logo" />
    <h1>ERROR</h1>
    <h2>404</h2>
    <p class="description">
      <span style="color: red; font-size: 50px" class="oops-text">Oops!</span> The page you are looking for doesn't
      exist. Don't worry, you can easily navigate back to where you need to be.
    </p>
    <div class="navigation">
      <button (click)="goToLogin()" class="styled-btn">Go to <strong>Login</strong></button>
    </div>
  </div>
</div>
<app-footer></app-footer>
