export class POMedicine {
  _id: string;
  name: string;
  price: number;
  tax_slab: any;
  discount: any;
  discountType?: string;
  discPercAmt?: number;
  original_total_price: number;
  qty: number;
  totalPrice: number;
  category: string;
  created_by: string;
  updated_by: string;
  drug: string;
  batch: string;
  checkup: string;
  po: string;
  doctor: string;
}
