<div class="container">
  <!-- Company profile -->
  <div class="clinicDetails" style="text-align: center; font-size: 40px">
    <p>
      <img alt="Logo" [src]="mainData?.clinic?.logo_url" class="invoice-image" style="max-width: 200px" />
    </p>
    <p style="font-size: 14px; text-align: center; margin: 0px auto; text-transform: uppercase; margin-bottom: 2px">
      <b>{{ mainData?.clinic?.name }}</b>
    </p>
    <p style="font-size: 12px; width: auto; text-align: center; margin: 0px auto">
      <b>Branch:</b> {{ mainData?.clinic?.address }}
    </p>
    <p style="font-size: 12px; max-width: auto; text-align: center; margin: 0px auto">
      <b>(Contact : </b>{{ mainData?.clinic?.phone_no }})
    </p>
    <hr style="margin-top: 9px; margin-bottom: 9px; border-color: white" />
    <h4 style="text-align: center">STORE INVOICE</h4>
  </div>

  <!-- <p style="font-size: 12px; max-width: 350px; text-align: center; margin: 0px auto">
    <b>(GST:</b> {{ mainData?.clinic?.store_gst }})
  </p> -->

  <!-- Customer Info -->
  <div class="customerData" style="padding: 0px 5px; width: auto">
    <table style="width: 100%; font-size: 12px; font-weight: 600">
      <tbody>
        <tr style="vertical-align: top">
          <td>Customer Name</td>
          <td>:{{ mainData?.customer?.first_name }} {{ mainData?.customer?.last_name }}</td>
        </tr>
        <tr style="vertical-align: top">
          <td>Mobile No</td>
          <td>:{{ mainData?.customer?.phone_no }}</td>
        </tr>
        <tr style="vertical-align: top">
          <td>Bill No.</td>
          <td>: {{ mainData?.invoice_no }}</td>
        </tr>
        <tr style="vertical-align: top">
          <td>Invoice Date</td>
          <td>: {{ mainData?.completed_at_date | date: 'dd-MM-yyyy' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Order summary -->
  <div class="orderSummary">
    <table
      style="width: 100%; font-size: 12px; font-weight: 600; border-top: 1px solid #8c8c8c; border-collapse: collapse"
    >
      <thead class="tableHeader">
        <tr style="font-size: 11px">
          <td style="padding: 0px 2px 12px 0px; border-bottom: 1px solid #8c8c8c">
            <strong>Catagory</strong>
          </td>
          <td style="padding: 0px 2px 12px 0px; border-bottom: 1px solid #8c8c8c"><strong>Qty</strong></td>
          <td style="padding: 0px 2px 12px 0px; border-bottom: 1px solid #8c8c8c"><strong>Tax</strong></td>
          <!-- <td style="padding: 0px 0px 12px 0px; border-bottom: 1px solid #8c8c8c"><strong>HSN</strong></td> -->
          <td style="padding: 0px 2px 12px 0px; border-bottom: 1px solid #8c8c8c"><strong>MRP</strong></td>
          <td style="padding: 0px 2px 12px 0px; border-bottom: 1px solid #8c8c8c"><strong>Dis</strong></td>
          <td style="padding: 0px 2px 12px 0px; border-bottom: 1px solid #8c8c8c"><strong>Price</strong></td>
          <td style="padding: 0px 2px 12px 0px; border-bottom: 1px solid #8c8c8c"><strong>Total</strong></td>
        </tr>
      </thead>
      <tbody>
        <tr style="font-size: 12px" *ngFor="let item of this.rowData; let index = index">
          <td
            style="padding: 5px 2px; vertical-align: top; width: 10px; word-break: break-all"
            *ngIf="item.category && item.category.length > 1"
          >
            {{ item?.category.toLowerCase() }}
          </td>
          <td style="padding: 5px 2px; vertical-align: top">{{ item?.qty }}<br /></td>
          <td style="padding: 5px 2px; vertical-align: top">{{ item?.tax_slab }}%</td>
          <!-- <td style="padding: 5px 5px; vertical-align: top">{{ item?.hsn?.code }}</td> -->
          <td style="padding: 5px 2px; vertical-align: top">
            {{ item?.original_unit_price }}
          </td>
          <td style="padding: 5px 2px; vertical-align: top">
            {{ item?.discount }}
            <!-- if rupee symbol needed, add this after variable => | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN'  -->
          </td>
          <td style="padding: 5px 2px; vertical-align: top">
            {{ item?.price }}
          </td>
          <td style="padding: 5px 2px; vertical-align: top">
            {{ item?.total_price }}
          </td>
        </tr>
      </tbody>
    </table>
    <hr style="margin-top: 9px; margin-bottom: 9px; border-color: #8c8c8c" />

    <div class="finalTransaction" style="padding: 0px 5px; width: auto">
      <table style="width: 100%; font-size: 12px; font-weight: 600">
        <tbody>
          <tr style="vertical-align: top">
            <td>Addn Discount</td>
            <td>: {{ mainData?.discount | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}</td>
          </tr>
          <tr style="vertical-align: top">
            <td>Sub-Total</td>
            <td>: {{ subTotal | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}</td>
          </tr>
          <ng-template ngFor let-tItem [ngForOf]="taxData" let-index="index">
            <tr style="vertical-align: top">
              <td>SGST ({{ tItem?.tax_slab / 2 }}%)</td>
              <td>: {{ tItem?.amount / 2 | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}</td>
            </tr>
            <tr style="vertical-align: top">
              <td>CGST ({{ tItem?.tax_slab / 2 }}%)</td>
              <td>: {{ tItem?.amount / 2 | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}</td>
            </tr>
          </ng-template>
          <tr style="vertical-align: top">
            <td>Tax</td>
            <td>: {{ tax_amount | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}</td>
          </tr>
          <tr style="vertical-align: top">
            <td>Total Billed Amount</td>
            <td>: {{ mainData?.amount | currency: 'INR' : 'symbol-narrow' : '1.0-2' : 'en-IN' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="thank-you" style="text-align: center">
    <p style="text-align: center; font-size: 12px; font-weight: 600">***THANK YOU. PLEASE VISIT AGAIN***</p>
  </div>
  <br />
  <div class="printbtn" style="text-align: center">
    <button (click)="print()" class="printbtn btn btn-info">Print</button>
  </div>
</div>
