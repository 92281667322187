import { Clinic, Plan } from '..';

export class Doctor {
  first_name: string;
  last_name: string;
  
  salutation: string;
  name: string;
  email_id: string;
  status: string;
  address: string;
  key_skills: string;
  qualification: string;
  profile_image_url: string;
  digital_signature_url?: string;
  about: string;
  subordinate_flag: boolean;
  _id: string;
  phone_no: string;
  activation_token: string;
  settings: {
    inclinic_available: boolean;
    phone_consultation_available: boolean;
    consultation_fee: number;
    veterinary_service_charges: number;
  };
  clinics: [
    {
      _id: string;
      clinic: Clinic;
    }
  ];
  plan: Plan;
  updated_at: string;
  created_at: string;
  activated_at: string;
}
