import { DatePipe, formatDate } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import jspdf from 'jspdf';
import autoTable from 'jspdf-autotable';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/shared-module/constants';
import { GlobalResponse } from 'src/app/shared-module/models';
import { NonSecureService } from '../non-secure.service';

@Component({
  selector: 'app-store-invoice-template',
  templateUrl: 'store-invoice-template.component.html',
  styleUrls: ['store-invoice-template.component.scss'],
  providers: [DatePipe]
})
export class StoreInvoiceTemplateComponent implements OnInit {
  mainData: any = {};
  invoice_id: string;
  base64ImageString: any;
  rowData: any[] = [];
  taxData: any[] = [];
  @BlockUI() blockUI: NgBlockUI;
  subTotal = 0;
  tax_amount = 0;
  line_discount_amount = 0;

  showTax: boolean = true;

  constructor(
    private titleService: Title,
    private toastr: ToastrService,
    private service: NonSecureService,
    private datepipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.invoice_id = this.route.snapshot.paramMap.get('invoice_id');
    if (this.invoice_id.length > 10) {
      this.fetchInvoiceDetails();
    } else {
      this.router.navigate([Constants.PET_DASHBOARD_URL]);
    }
  }

  fetchInvoiceDetails() {
    this.blockUI.start();
    this.service.fetchStoreInvoiceDetails(this.invoice_id).subscribe(
      (res: GlobalResponse) => {
        this.blockUI.stop();
        if (res && res.message && res.message === Constants.STATUS_SUCCESS && res.result) {
          this.mainData = res.result;
          this.subTotal = Math.floor(this.mainData.discount + this.mainData.amount);
          this.titleService.setTitle('Invoice ' + this.mainData.invoice_no);
          this.createMainData(this.mainData.medicines);
          if (this.mainData.tax) {
            this.taxData = this.mainData.tax;
            this.tax_amount = this.sumArrayValue(this.mainData.tax);
          }
          if (this.mainData.clinic.logo_url && this.mainData.clinic.logo_url.length > 10) {
            this.getBase64ImageFromUrl(this.mainData.clinic.logo_url).then(base64 => {
              this.base64ImageString = base64;
            });
          }

          if (window.innerWidth <= 700) {
            setTimeout(() => {
              this.actionButton('print');
            }, 700);
          }
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.toastr.error(error);
      }
    );
  }

  toggleTaxColumn() {
    this.showTax = !this.showTax;
  }

  createMainData(listData: any) {
    for (const item of listData) {
      const tempItem = {} as any;
      if (item) {
        tempItem.name = item.name;
        tempItem.category = item.category;

        tempItem.qty = item.qty;
        tempItem.price = item.unitPrice;
        tempItem.total_price = item.totalPrice;
        tempItem.original_total_price = item.original_total_price;
        tempItem.original_unit_price = item.original_unit_price;
        tempItem._id = item._id;
        tempItem.drug = item.drug;
        tempItem.discount = item.discount;
        this.line_discount_amount += item.discount;
        tempItem.tax_slab = item.tax_slab;
        tempItem.hsn = item.hsn;
        tempItem.batch = item.batch ? item.batch : null;
        // if (tempItem.price !== 0) {
        this.rowData.push(tempItem);
        // }
      }
    }
  }

  sumArrayValue(array) {
    if (array && array.length > 0) {
      return array.map(obj => obj.amount).reduce((accumulator, current) => accumulator + current, 0);
    } else {
      return 0;
    }
  }

  async getBase64ImageFromUrl(imageUrl) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result);
        },
        false
      );
      reader.onerror = () => reject(this);

      reader.readAsDataURL(blob);
    });
  }

  actionButton(type: string) {
    const doc = new jspdf('p', 'mm', 'a4');

    doc.setFontSize(18);
    doc.setTextColor(40);

    if (this.showTax === false) {
      if (this.mainData.clinic.logo_url && this.mainData.clinic.logo_url.length > 10) {
        doc.addImage(this.base64ImageString, 170, 5, 20 * 1.5, 20);
      }

      if (this.mainData.clinic && this.mainData.clinic.store_gst) {
        doc.line(10, 40, 200, 40);
        doc.line(10, 60, 200, 60);
      } else {
        doc.line(10, 42, 200, 42);
        doc.line(10, 62, 200, 62);
      }
    } else {
      if (this.mainData.clinic.logo_url && this.mainData.clinic.logo_url.length > 10 && this.base64ImageString) {
        doc.addImage(this.base64ImageString, 170, 15, 20 * 2, 30);
      }

      if (this.mainData.clinic && this.mainData.clinic.store_gst) {
        doc.line(10, 45, 200, 45);
        doc.line(10, 65, 200, 65);
      } else {
        doc.line(10, 42, 200, 42);
        doc.line(10, 62, 200, 62);
      }
    }
    const tempArray = [];
    for (const [index, item] of this.rowData.entries()) {
      const tempObj: any = {} as any;
      tempObj.index = index + 1;
      tempObj.name = item.name;
      tempObj.category = item.category;
      tempObj.price = Number(item.price).toFixed(2);
      tempObj.original_unit_price =
        item.original_unit_price && item.original_unit_price >= 0
          ? Number(item.original_unit_price).toFixed(2)
          : Number(item.price).toFixed(2);
      tempObj.qty = item.qty;
      tempObj.tax = item.tax_slab;
      tempObj.hsn = item.hsn && item.hsn.code ? item.hsn.code : '';
      tempObj.expiry_date =
        item.batch && item.batch.expiry_date ? formatDate(new Date(item.batch.expiry_date), 'MM-yy', 'en-US') : '';
      tempObj.discount = item.discount >= 0 ? Number(item.discount).toFixed(2) : '';
      tempObj.total_price = Number(item.total_price).toFixed(2);
      tempArray.push(tempObj);
    }
    let mainHeaderItem = [];
    const clinicNameItem = [
      {
        content: this.mainData.clinic.name,
        styles: {
          halign: 'left',
          fontSize: 20
        }
      }
    ];
    const clinicAddrItem = [
      {
        content: this.mainData.clinic.address,
        styles: {
          halign: 'left',
          cellPadding: 1,
          fontSize: 7,
          textColor: 'black'
        }
      }
    ];
    const clinicPhoneItem = [
      {
        content: 'Phone: ' + this.mainData.clinic.phone_no,
        styles: {
          halign: 'left',
          cellPadding: 1,
          fontSize: 8
        }
      }
    ];

    //GST License Item
    let clinicGSTLincenseItem = [];
    if (this.mainData.clinic.store_gst && this.mainData.clinic.license_number && this.showTax) {
      clinicGSTLincenseItem = [
        {
          content:
            'GST: ' +
            this.mainData.clinic.store_gst +
            '    ||      ' +
            'License: ' +
            this.mainData.clinic.license_number,
          styles: {
            halign: 'left',
            cellPadding: 1,
            fontSize: 8
          }
        }
      ];
    } else if (this.mainData.clinic.store_gst && !this.mainData.clinic.license_number && this.showTax) {
      clinicGSTLincenseItem = [
        {
          content: 'GST: ' + this.mainData.clinic.store_gst,
          styles: {
            halign: 'left',
            cellPadding: 1,
            fontSize: 8
          }
        }
      ];
    } else if (!this.mainData.clinic.store_gst && this.mainData.clinic.license_number) {
      clinicGSTLincenseItem = [
        {
          content: 'License:' + this.mainData.clinic.license_number,
          styles: {
            halign: 'left',
            cellPadding: 1,
            fontSize: 8
          }
        }
      ];
    }

    mainHeaderItem = [clinicNameItem, clinicAddrItem, clinicPhoneItem, clinicGSTLincenseItem];

    autoTable(doc, {
      body: mainHeaderItem,
      theme: 'plain'
    });

    const headerDetailsArrayRow1 = [
      {
        content: 'Owner Name: ' + this.mainData.customer.first_name + ' ' + this.mainData.customer.last_name,
        styles: {
          halign: 'left'
        }
      },
      {
        content: 'Bill No: ' + this.mainData.invoice_no,
        styles: {
          halign: 'left'
        }
      },
      {
        content: 'Date: ' + this.datepipe.transform(this.mainData.date, 'dd-MM-yyyy'),
        styles: {
          halign: 'left'
        }
      }
    ];
    const headerDetailsArrayRow2 = [
      {
        content: 'Owner Phone No: ' + this.mainData.customer.phone_no,
        styles: {
          halign: 'left'
        }
      }
    ];

    const headerArray = [];
    headerArray.push(headerDetailsArrayRow1);
    headerArray.push(headerDetailsArrayRow2);
    autoTable(doc, {
      body: headerArray,
      theme: 'plain'
    });

    //Table Details
    if (this.showTax === false) {
      autoTable(doc, {
        head: [['#', 'Name', 'Category', 'Qty', 'Expiry', 'MRP', 'Discount', 'Price', 'Total']],
        body: tempArray,
        columns: [
          { header: '#', dataKey: 'index' },
          { header: 'Name', dataKey: 'name' },
          { header: 'Category', dataKey: 'category' },
          { header: 'Qty', dataKey: 'qty' },
          { header: 'Expiry', dataKey: 'expiry_date' },
          { header: 'MRP', dataKey: 'original_unit_price' },
          { header: 'Discount', dataKey: 'discount' },
          { header: 'Price', dataKey: 'price' },
          { header: 'Amount', dataKey: 'total_price' }
        ],
        theme: 'striped',
        tableLineWidth: 0.2,
        headStyles: {
          fillColor: '#3b21db',
          fontSize: 8
        },
        columnStyles: {
          0: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          1: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          2: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          4: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          5: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          6: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          7: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          8: { halign: 'left', cellWidth: 'auto', fontSize: 8 }
        }
      });
    } else {
      autoTable(doc, {
        head: [['#', 'Name', 'Category', 'Qty', 'Tax %', 'HSN', 'Expiry', 'MRP', 'Discount', 'Price', 'Total']],
        body: tempArray,
        columns: [
          { header: '#', dataKey: 'index' },
          { header: 'Name', dataKey: 'name' },
          { header: 'Category', dataKey: 'category' },
          { header: 'Qty', dataKey: 'qty' },
          { header: 'Tax %', dataKey: 'tax' },
          { header: 'HSN', dataKey: 'hsn' },
          { header: 'Expiry', dataKey: 'expiry_date' },
          { header: 'MRP', dataKey: 'original_unit_price' },
          { header: 'Discount', dataKey: 'discount' },
          { header: 'Price', dataKey: 'price' },
          { header: 'Amount', dataKey: 'total_price' }
        ],
        theme: 'striped',
        tableLineWidth: 0.2,
        headStyles: {
          fillColor: '#3b21db',
          fontSize: 8
        },
        columnStyles: {
          0: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          1: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          2: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          3: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          4: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          5: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          6: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          7: { halign: 'left', cellWidth: 'auto', fontSize: 8 },
          8: { halign: 'left', cellWidth: 'auto', fontSize: 8 }
        }
      });
    }
    const bottomSubTotalAmt = [
      [
        { content: 'Sub-Total (in Rs):', styles: { halign: 'right' } },
        { content: this.subTotal, styles: { halign: 'right' } }
      ]
    ];

    let bottomAddnDiscntAmt = [];
    if (this.mainData.discount) {
      bottomAddnDiscntAmt = [
        [
          { content: 'Addn Discount (in Rs):', styles: { halign: 'right' } },
          { content: this.mainData.discount, styles: { halign: 'right' } }
        ]
      ];
    }

    const bottomTtlBilledAmt = [
      [
        { content: 'Total Billed Amount (in Rs):', styles: { halign: 'right', fontStyle: 'bold' } },
        { content: this.mainData.amount, styles: { halign: 'right', fontStyle: 'bold' } }
      ]
    ];

    let bottomTotalTaxItem = [];
    if (this.showTax && this.tax_amount) {
      bottomTotalTaxItem = [
        [
          { content: 'Total Tax (in Rs):', styles: { halign: 'right', fontStyle: 'bold' } },
          { content: this.tax_amount.toFixed(2), styles: { halign: 'right' } }
        ]
      ];
    }

    let completedByItem = [];
    if (this.mainData.completed_by) {
      completedByItem = [
        [
          {
            content:
              'Completed By: ' +
              this.mainData.completed_by.salutation +
              ' ' +
              this.mainData.completed_by.first_name +
              ' ' +
              this.mainData.completed_by.last_name,
            styles: { halign: 'left', fontSize: 7 }
          },
          { content: '' }
        ]
      ];
    }

    let taxArrayItem = [];
    if (this.showTax) {
      for (const tItem of this.taxData) {
        if (tItem.tax_slab > 0) {
          const tempCTaxItem = [
            { content: `CGST (${tItem.tax_slab / 2}%):`, styles: { halign: 'right', fontSize: 7 } },
            { content: (tItem.amount / 2).toFixed(2), styles: { halign: 'right', fontSize: 7 } }
          ];
          const tempSTaxItem = [
            { content: `SGST (${tItem.tax_slab / 2}%):`, styles: { halign: 'right', fontSize: 7 } },
            { content: (tItem.amount / 2).toFixed(2), styles: { halign: 'right', fontSize: 7 } }
          ];
          taxArrayItem.push(tempCTaxItem);
          taxArrayItem.push(tempSTaxItem);
        }
      }
    }

    // Flatten the rows and remove empty rows
    const bottomArray = [
      ...bottomSubTotalAmt,
      ...bottomAddnDiscntAmt,
      ...taxArrayItem,
      ...bottomTotalTaxItem,
      ...bottomTtlBilledAmt,
      ...completedByItem
    ];

    // Pass to autoTable
    autoTable(doc, {
      body: bottomArray,
      theme: 'plain'
    });

    autoTable(doc, {
      body: [
        [
          {
            content: 'Powered by PawsNme',
            styles: {
              halign: 'center',
              textColor: 'black',
              fontSize: 8
            }
          }
        ],
        [
          {
            content: 'This is Computer Generated Invoice, No Signature Required',
            styles: {
              halign: 'center',
              fontSize: 8
            }
          }
        ]
      ],
      theme: 'plain'
    });
    if (type === 'download') {
      return doc.save('Store Invoice_' + this.mainData.invoice_no + '.pdf');
    } else if (type === 'print') {
      doc.autoPrint({ variant: 'non-conform' });
      const blob = doc.output('blob');
      window.open(URL.createObjectURL(blob));
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (window.innerWidth <= 700) {
      setTimeout(() => {
        this.actionButton('download');
      }, 700);
    }
  }
}
