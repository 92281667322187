import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatChipsModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatSelectModule,
  MatTableModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { MyDatePickerModule } from 'mydatepicker';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { BlockUIModule } from 'ng-block-ui';
import { ChartsModule } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { WebcamModule } from 'ngx-webcam';
import { AddEditQtyTaxComponent } from './add-edit-qty-tax/add-edit-qty-tax.component';
import { AdvancePaymentDialogComponent } from './advance-payment-dialog/advance-payment-dialog.component';
import { BarcodeItemSelectionDialogComponent } from './barcode-item-selection-dialog/barcode-item-selection-dialog.component';
import { CameraDialogComponent } from './camera-dialog/camera-dialog.component';
import { CameraComponent } from './camera/camera.component';
import { CancelCheckupDialogComponent } from './cancel-checkup-dialog/cancel-checkup-dialog.component';
import { CommonService } from './common-service.service';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CreateDrugDialogComponent } from './create-drug-dialog/create-drug-dialog.component';
import { AgePipe } from './directive/age.pipe';
import { DecimalFormatPipe } from './directive/decimal.pipe';
import { SelectTextDirective } from './directive/input-select.directive';
import { InputTextDirective } from './directive/input-text.directive';
import { MonthNamePipe } from './directive/month.pipe';
import { NumberCurrencyPipe } from './directive/number-currency.pipe';
import { StatusFormatterPipe } from './directive/status-formatter.pipe';
import { FooterComponent } from './footer/footer.component';
import { HeaderNonLoginComponent } from './header-non-login/headernonlgn.component';
import { HeaderComponent } from './header/header.component';
import { LoginFooterComponent } from './login-footer/login-footer.component';
import { MainLayoutNonLoginComponent } from './main-layout-non-login/main-layout-nonlgn.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { PaymentDialogComponent } from './payment-dialog/payment-dialog.component';
import { RegisterCustomerDialogComponent } from './register-customer-dialog/register-customer-dialog.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { StatusDialogComponent } from './status-dialog/status-dialog.component';
import { UpdateHSNDialogComponent } from './update-hsn-dialog/update-hsn-dialog.component';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { StorePaymentDialogComponent } from './store-payment-dialog/payment-dialog.component';

@NgModule({
  declarations: [
    HeaderComponent,
    CancelCheckupDialogComponent,
    FooterComponent,
    LoginFooterComponent,
    HeaderNonLoginComponent,
    SidebarComponent,
    ConfirmationDialogComponent,
    StatusDialogComponent,
    PaymentDialogComponent,
    AdvancePaymentDialogComponent,
    UploadDialogComponent,
    AddEditQtyTaxComponent,
    MainLayoutComponent,
    MainLayoutNonLoginComponent,
    CameraDialogComponent,
    CameraComponent,
    InputTextDirective,
    AgePipe,
    MonthNamePipe,
    NumberCurrencyPipe,
    CreateDrugDialogComponent,
    BarcodeItemSelectionDialogComponent,
    RegisterCustomerDialogComponent,
    UpdateHSNDialogComponent,
    DecimalFormatPipe,
    SelectTextDirective,
    StatusFormatterPipe,
    StorePaymentDialogComponent
  ],
  imports: [
    FormsModule,
    OverlayModule,
    CommonModule,
    RouterModule,
    BlockUIModule.forRoot(),
    ToastrModule.forRoot(),
    NgSelectModule,
    MyDatePickerModule,
    MyDateRangePickerModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatIconModule,
    WebcamModule,
    ReactiveFormsModule,
    ChartsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatButtonModule
  ],
  providers: [
    CommonService,
    {
      provide: NgSelectConfig,
      useFactory: () => {
        const config = new NgSelectConfig();
        config.appendTo = 'body';
        return config;
      }
    }
  ],
  exports: [
    FormsModule,
    RouterModule,
    CommonModule,
    HeaderComponent,
    HeaderNonLoginComponent,
    FooterComponent,
    LoginFooterComponent,
    SidebarComponent,
    BlockUIModule,
    ToastrModule,
    NgSelectModule,
    MyDatePickerModule,
    MyDateRangePickerModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MainLayoutComponent,
    MainLayoutNonLoginComponent,
    MatIconModule,
    WebcamModule,
    ReactiveFormsModule,
    ChartsModule,
    InputTextDirective,
    AgePipe,
    MonthNamePipe,
    StatusFormatterPipe,
    NumberCurrencyPipe,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatButtonModule,
    DecimalFormatPipe,
    SelectTextDirective,
    StorePaymentDialogComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    StatusDialogComponent,
    PaymentDialogComponent,
    CancelCheckupDialogComponent,
    AdvancePaymentDialogComponent,
    UploadDialogComponent,
    AddEditQtyTaxComponent,
    CameraDialogComponent,
    BarcodeItemSelectionDialogComponent,
    CreateDrugDialogComponent,
    RegisterCustomerDialogComponent,
    UpdateHSNDialogComponent,
    StorePaymentDialogComponent
  ]
})
export class SharedModule {}
