import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './extra-module/auth-intercept/auth-guard.service';
import { ClinicInvoiceTemplateComponent } from './non-secure-pages/clinic-invoice-template/clinic-invoice-template.component';
import { HomeComponent } from './non-secure-pages/home/home.component';
import { LoginComponent } from './non-secure-pages/login/login.component';
// import { PageNotFoundComponent } from './non-secure-pages/page-not-found/page-not-found.component';
import { PrescriptionTemplateComponent } from './non-secure-pages/prescription-template/prescription-template.component';
import { ClinicRegistrationComponent } from './non-secure-pages/register/clinic-registration.component';
import { StoreInvoiceTemplateComponent } from './non-secure-pages/store-invoice-template/store-invoice-template.component';
import { CertificateTemplateComponent } from './pet-certificates/certificate-template/certificate-template.component';
import { SubscriptionExpiryPageComponent } from './non-secure-pages/subscription-expiry-page/subscription-expiry-page.component';
import { SmallStoreInvoiceComponent } from './non-secure-pages/small-store-invoice/small-store-invoice.component';
import { NotFoundPageComponent } from './non-secure-pages/not-found-page/not-found-page.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent, data: { title: 'Login', enableExtraHeader: false, pageAccess: 'All' } },
  { path: 'doctor-registration', redirectTo: '/clinic-registration', pathMatch: 'full' },
  {
    path: 'clinic-registration',
    component: ClinicRegistrationComponent,
    data: { title: 'Register Doctor', enableExtraHeader: false, pageAccess: 'All' }
  },
  {
    path: 'clinicinvoice/:invoice_id',
    component: ClinicInvoiceTemplateComponent,
    data: { title: 'Clinic Invoice', enableExtraHeader: false, pageAccess: 'All' }
  },
  {
    path: 'storeinvoice/:invoice_id',
    component: StoreInvoiceTemplateComponent,
    data: { title: 'Store Invoice', enableExtraHeader: false, pageAccess: 'All' }
  },
  {
    path: 'petcertificate/:certificateID',
    component: CertificateTemplateComponent,
    data: { title: 'Certificate', enableExtraHeader: false, pageAccess: 'All' }
  },
  {
    path: 'certificate',
    loadChildren: './pet-certificates/pet-certificates.module#PetCertificatesModule',
    canActivate: [AuthGuardService],
    data: { pageAccess: 'All' }
  },
  {
    path: 'smallstoreinvoice/:invoice_id',
    component: SmallStoreInvoiceComponent,
    data: { title: 'Store Invoice Small', enableExtraHeader: false, pageAccess: 'All' }
  },
  {
    path: 'prescription/:prescription_id',
    component: PrescriptionTemplateComponent,
    data: { title: 'Prescription', enableExtraHeader: false, pageAccess: 'All' }
  },
  {
    path: 'transactions',
    loadChildren: './transactions/transactions.module#TransactionsModule',
    canActivate: [AuthGuardService],
    data: { pageAccess: 'MainDoctor' }
  },
  { path: 'inpatient', loadChildren: './inpatient/inpatient.module#InpatientModule', data: { pageAccess: 'All' } },
  { path: 'billing', loadChildren: './billing/billing.module#BillingModule', data: { pageAccess: 'All' } },
  { path: 'profile', loadChildren: './profile/profile.module#ProfileModule', data: { pageAccess: 'All' } },
  {
    path: 'pet/checkup',
    loadChildren: './pet-checkup/pet-checkup.module#PetCheckupModule',
    data: { pageAccess: 'All' }
  },
  { path: 'pet', loadChildren: './pet/pet.module#PetModule', data: { pageAccess: 'All' } },
  { path: 'inventory', loadChildren: './inventory/inventory.module#InventoryModule', data: { pageAccess: 'All' } },
  { path: 'hsn', loadChildren: './hsn/hsn.module#HsnModule', data: { pageAccess: 'All' } },
  { path: 'store', loadChildren: './store/store.module#StoreModule', data: { pageAccess: 'All' } },
  {
    path: 'expense',
    loadChildren: './clinical-expenses/clinical-expenses.module#ClinicalExpensesModule',
    data: { pageAccess: 'MainDoctor' }
  },
  {
    path: 'subscription-expired',
    component: SubscriptionExpiryPageComponent
  },
  { path: '**', component: NotFoundPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
