import { Pet } from './pet';
export class Customer {
  _id: string;
  first_name: string;
  last_name: string;
  email_id: string;
  status: string;
  address: Address;
  profile_image_url: string;
  phone_no: number;
  pets: Pet[] = [];
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  pincode: number;
}
