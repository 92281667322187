import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ClinicInvoiceTemplateComponent } from './clinic-invoice-template/clinic-invoice-template.component';
import { LoginComponent } from './login/login.component';
import { PrescriptionTemplateComponent } from './prescription-template/prescription-template.component';
import { ClinicRegistrationComponent } from './register/clinic-registration.component';
import { StoreInvoiceTemplateComponent } from './store-invoice-template/store-invoice-template.component';
import { SmallStoreInvoiceComponent } from './small-store-invoice/small-store-invoice.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent, data: { title: 'Login', enableExtraHeader: false } },
  {
    path: 'clinic-registration',
    component: ClinicRegistrationComponent,
    data: { title: 'Register Doctor', enableExtraHeader: false }
  },
  {
    path: 'clinicinvoice/:invoice_id',
    component: ClinicInvoiceTemplateComponent,
    data: { title: 'Clinic Invoice', enableExtraHeader: false }
  },
  {
    path: 'storeinvoice/:invoice_id',
    component: StoreInvoiceTemplateComponent,
    data: { title: 'Store Invoice', enableExtraHeader: false }
  },
  {
    path: 'smallstoreinvoice/:invoice_id',
    component: SmallStoreInvoiceComponent,
    data: { title: 'Store Invoice Small', enableExtraHeader: false }
  },
  {
    path: 'prescription/:prescription_id',
    component: PrescriptionTemplateComponent,
    data: { title: 'Prescription', enableExtraHeader: false }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NonSecureRoutingModule {}
